import APIService from './APIService';

export default {
  getDadosTabelaVisaoGeral(competencia, competencia_comparacao) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`painel-tecnicos/get-dados-tabela-visao-geral?competencia=${competencia}&competencia_comparacao=${competencia_comparacao}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosValidacoesVisaoGeral(competencia) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`painel-tecnicos/get-dados-validacoes-visao-geral?competencia=${competencia}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getNovosCodigosByCompetencia(competencia) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`painel-tecnicos/get-novos-codigos-by-competencia?competencia=${competencia}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  getClientesAndReclamacaoGroupByCompetencia(competenciaDe, competenciaAte) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`painel-tecnicos/get-clientes-and-reclamacao-group-by-competencia?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  getReclamacaoByIdentificacao(competencia) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`painel-tecnicos/get-reclamacao-by-identificacao?competencia=${competencia}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getQtdTiposValidacao(
    competenciaDe, 
    competenciaAte, 
    userId, 
    authorizedCategoriesTecAl, 
    authorizedCategoriesTecSub, 
    authorizedCategoriesTecLd
  ) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`painel-tecnicos/get-qtd-tipos-validacao?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&user_id=${userId}&categories_tec_al=${authorizedCategoriesTecAl}&categories_tec_sub=${authorizedCategoriesTecSub}&categories_tec_ld=${authorizedCategoriesTecLd}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  getDadosValidacoes(competenciaDe, competenciaAte, base) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`painel-tecnicos/get-dados-validacoes?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&base=${base}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  downloadArquivoValidacoes(competenciaDe, competenciaAte, config) {
    return APIService.apiCall().get(`painel-tecnicos/download-arquivo-validacoes?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}`, {
      responseType: 'blob',  
      ...config
    });
  },
  getNomeArquivoValidacoes() {
    return APIService.apiCall().get(`painel-tecnicos/get-nome-arquivo-validacoes`);
  },
  salvarAprovacoesValidacoes(base, postData) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().post(`painel-tecnicos/salvar-aprovacoes-validacoes?base=${base}`, JSON.stringify(postData))
        .then(()  => resolve(true))
        .catch(() => reject(false));
    });
  },
  downloadArquivoLogsAprovacoes(competenciaDe, competenciaAte, base, config) {
    return APIService.apiCall().get(`painel-tecnicos/download-arquivo-logs-aprovacoes?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&base=${base}`, {
      responseType: 'blob',  
      ...config
    });
  },
}